import { Trans, useTranslation } from 'react-i18next'
import { z } from 'zod'
import { zodI18nMap } from 'zod-i18n-map'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import Input from './fields/Input'
import Button from '../buttons/Button'
import Radio from './fields/Radio'
import {
  panelMountingOptions,
  shortRailPanelMountingOptions,
  corrugatedTinMetalPanelMountingOptions
} from './formOptions'
import Checkbox from './fields/Checkbox'
import React, { useEffect, useState } from 'react'
import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '../../store'
import Info from '../Info'
import {
  validateRailDistance,
  DistanceValidation,
  inSessionStorage,
  removeFromSessionStorage,
  inferRailDistanceFromPanel,
  resetRailDistance
} from '~/lib/utils'
import { t } from 'i18next'
import { cn } from '~/utils/tailwind'

const validationSchema = z.object({
  system: z.string(),
  width: z.number(),
  height: z.number(),
  weight: z.number(),
  mounting: z.string(),
  useSupportPlates: z.boolean(),
  useThreeRails: z.boolean(),
  selectedRailDistance: z.number()
}).superRefine((schema, ctx) => {
  parallellRailDistanceRefiner(schema, ctx)
})

type ValidationSchema = z.infer<typeof validationSchema>
z.setErrorMap(zodI18nMap)

const parallellRailDistanceRefiner = (schema: ValidationSchema, ctx: z.RefinementCtx) => {
  const distanceValidation = validateRailDistance(
    schema.width, schema.height,
    {mounting: schema.mounting, system: schema.system},
    schema.selectedRailDistance
  )
  switch (distanceValidation) {
    case DistanceValidation.TooSmall:
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t("Önskat avstånd är för litet"),
        path: ['selectedRailDistance']
      })
      break;
    case DistanceValidation.TooLarge:
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t("Önskat avstånd är för stort"),
        path: ['selectedRailDistance']
      })
      break;
    default:
      break;
  }
}

const FormPanelSettings = React.memo(() => {
  const { t } = useTranslation()

  const {
    roof,
    covering,
    activeArea,
    panelAreas,
    panelInfo,
    setPanelInfo,
    setShowPanelSettings,
    updatePanelArea
  } = useBoundStore(
    (state: StoreState) => ({
      roof: state.roof,
      covering: state.roof.covering,
      activeArea: state.activeArea,
      panelAreas: state.panelAreas,
      panelInfo: state.panelInfo,
      setPanelInfo: state.setPanelInfo,
      setShowPanelSettings: state.setShowPanelSettings,
      updatePanelArea: state.updatePanelArea
    }),
    shallow
  )

  const panelAreaIndex = panelAreas.findIndex(
    (panelArea) => panelArea.uid === activeArea
  )

  useEffect(() => {
    if (activeArea !== null && panelAreaIndex !== -1) {
      const panelInfo = panelAreas[panelAreaIndex].panelInfo
      reset(panelInfo)
    }
  }, [activeArea])

  const selectedArea = activeArea !== null

  const form = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      system: 'parallel',
      width: panelInfo.width,
      height: panelInfo.height,
      weight: panelInfo.weight,
      mounting: panelInfo.mounting,
      useSupportPlates: panelInfo.useSupportPlates,
      useThreeRails: panelInfo.useThreeRails,
      selectedRailDistance: panelInfo.selectedRailDistance
    }
  })

  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    clearErrors,
    formState: { isDirty }
  } = form

  const width = watch('width')
  const height = watch('height')
  const mounting = watch('mounting')
  const railDistance = watch('selectedRailDistance')

  const [isPortrait, setIsPortrait] = useState(width < height)

  const calculatePanelWeight = (width: number, height: number) => {
    if (width > 0 && height > 0) {
      return Math.round((width / 1000) * (height / 1000) * 13)
    } else {
      return 0
    }
  }

  const [shouldCalculateWeight, setShouldCalculateWeight] = useState(false)

  useEffect(() => {
    if (shouldCalculateWeight) {
      setValue('weight', calculatePanelWeight(width, height))
    }
    setShouldCalculateWeight(true)
    const mountingStringArray = mounting.split('-')
    if (width > height) {
      if (mountingStringArray[1] === 'portrait') {
        mountingStringArray[1] = 'landscape'
        setValue('mounting', mountingStringArray.join('-'))
      }
    } else {
      if (mountingStringArray[1] !== 'portrait') {
        mountingStringArray[1] = 'portrait'
        setValue('mounting', mountingStringArray.join('-'))
      }
    }
    setIsPortrait(width < height)
  }, [width, height])

  useEffect(() => {
    if (
      ((mounting == '90-portrait' || mounting == '0-portrait') &&
        !isPortrait) ||
      ((mounting == '90-landscape' || mounting == '0-landscape') && isPortrait)
    ) {
      setShouldCalculateWeight(false)
      setValue('width', height)
      setValue('height', width)
    }
  }, [mounting])

  useEffect(() => {
    /** Do not update selected rail distance if user has explicitly set this in input field */
    const sessionRailDistance = sessionStorage.getItem('selectedRailDistance')
    if (sessionRailDistance != null) {
      setValue('selectedRailDistance', parseInt(sessionRailDistance))
      return;
    }

    const railDistance = inferRailDistanceFromPanel(width, height, {mounting, system: 'parallel'})
    setValue('selectedRailDistance', railDistance)
  }, [width, height, mounting, railDistance])

  useEffect(() => {
    const mountingStringArray = mounting.split('-')
    if (
      roof.covering === 'corrugated_tin_metal' ||
      (roof.attachment === 'short_rail' && mountingStringArray[0] === '0')
    ) {
      mountingStringArray[0] = '90'
    }
    setValue('mounting', mountingStringArray.join('-'))
  }, [roof])

  const onSubmit: SubmitHandler<ValidationSchema> = (data) => {
    let validData = null
    try {
      validData = validationSchema.parse(data)
      const panelInfoData = {
        ...panelInfo,
        ...validData,
        widthMounted: validData.width,
        heightMounted: validData.height
      }
      if (activeArea !== null) {
        updatePanelArea({
          ...panelAreas[panelAreaIndex],
          panelInfo: panelInfoData,
          railsPerRow: validData.useThreeRails ? 3 : 2,
          useSupportPlates: validData.useSupportPlates
        })
      } else {
        setPanelInfo(panelInfoData)
        localStorage.setItem('panelWidth', validData.width.toString())
        localStorage.setItem('panelHeight', validData.height.toString())
        localStorage.setItem('panelWeight', validData.weight.toString())
        localStorage.setItem('panelMounting', validData.mounting.toString())
        localStorage.setItem(
          'useSupportPlates',
          validData.useSupportPlates.toString()
        )
        localStorage.setItem(
          'useThreeRails',
          validData.useThreeRails.toString()
        )
        localStorage.setItem('system', validData.system)
      }
      setShowPanelSettings(false)
      reset({
        height: validData.height,
        width: validData.width,
        weight: validData.weight,
        mounting: validData.mounting,
        useSupportPlates: validData.useSupportPlates,
        useThreeRails: validData.useThreeRails
      })
    } catch (error) {
      if (error instanceof z.ZodError) {
        console.log(error.issues)
      }
    }
  }

  const getMountingOptions = () => {
    if (roof.attachment === 'short_rail') {
      return shortRailPanelMountingOptions
    }
    if (roof.covering === 'corrugated_tin_metal') {
      return corrugatedTinMetalPanelMountingOptions
    }
    return panelMountingOptions
  }

  return (
    <FormProvider {...form}>
      <form
        className="mb-6 grid w-full grid-cols-2 gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h2 className="heading-m col-span-full mb-6">
          {panelAreaIndex > -1
            ? `${t('Redigera inställningar - Panelyta')} ${panelAreaIndex + 1}`
            : t('Panelinställningar')}
        </h2>
        <h3 className="col-span-full text-lg font-bold">{t('Dimensioner')}</h3>
        <Input
          name="system"
          type="hidden"
        />
        <Input
          name="width"
          type="number"
          label={t('Bredd') || ''}
          className="col-span-1"
          required
          disabled={selectedArea}
        />
        <Input
          name="height"
          type="number"
          label={t('Höjd') || ''}
          className="col-span-1"
          required
          disabled={selectedArea}
        />
        <Input
          name="weight"
          type="number"
          label={t('Vikt') || ''}
          className="col-span-1 mb-4"
          required
          disabled={selectedArea}
        />
        <h3 className="col-span-full text-lg font-bold">
          {t('Panelmontering')}
        </h3>
        <Radio
          name="mounting"
          options={getMountingOptions()}
          vertical
          disabled={selectedArea}
          />
        {mounting === '0-landscape' && covering === 'corrugated_tin_metal' ? (
          <Trans i18nKey="system_page_info">
            <p className="col-span-full mb-8">
              <span className="font-bold">Information:</span> Tänk på att det är
              svårt att träffa klämzoner vid detta montage.
            </p>
          </Trans>
        ) : null}

        {/* Rail distance section */}
        <div className={cn('flex flex-col mb-2')}>
          <Input
            name="selectedRailDistance"
            type="number"
            icon={<Info iconSize='lg' id='selectedRailDistance-icon' text={t('Måttet som anges avser CC-mått.')}/>}
            label={t('Avstånd mellan klämzoner') || ''}
            className="col-span-1"
            onChange={(event) => { sessionStorage.setItem('selectedRailDistance', event.target.value) }}
            required
            unit={t('mm')}
          />
          {inSessionStorage('selectedRailDistance') ? (
            <button
              onClick={(e) => {
                e.preventDefault()
                resetRailDistance(width, height, {mounting, system: 'parallel'}, (railDistance) => {
                  setValue('selectedRailDistance', railDistance)
                  removeFromSessionStorage('selectedRailDistance')
                  clearErrors('selectedRailDistance')
                })
              }}
              className={cn('ml-auto font-light underline text-sm')}
              >
                {t('Återställ')}
            </button>
          ) : null}
        </div>
        
        <h3 className="col-span-full text-lg font-bold">
          {t('Tillval')} <span className="font-normal">{t('(valfritt)')}</span>
        </h3>
        {roof.covering == 'flat' &&
        ['sealing_plate_flat', 'sealing_plate_perforated'].includes(
          roof.attachment
        ) ? (
          <Checkbox
            name="useSupportPlates"
            label={t('Använd stödplattor')}
            className=""
          />
        ) : null}
        <Checkbox
          name="useThreeRails"
          label={t('Monteras med 3 skenor')}
          className="mb-6"
        />
        <div className="col-start-2 flex justify-end">
          <Button>{isDirty ? t('Uppdatera') : t('Klar')}</Button>
        </div>
      </form>
    </FormProvider>
  )
})

export default FormPanelSettings!
