import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/sharp-solid-svg-icons'
import { Tooltip } from 'react-tooltip'
import { SizeProp } from '@fortawesome/fontawesome-svg-core'
import { cn } from '~/utils/tailwind'

type Props = {
  id: string
  text: string
  iconClassName?: string
  iconSize?: SizeProp
}

const Info = ({ id, text, iconClassName, iconSize }: Props) => {
  return (
    <>
      <FontAwesomeIcon
        icon={faInfoCircle}
        size={iconSize}
        className={cn("px-2", iconClassName)}
        data-tooltip-id={id}
      />
      <Tooltip
        id={id}
        className='normal-case z-10'
        content={text}
        style={{
          maxWidth: '178px',
          fontWeight: '300',
          fontSize: '14px',
          padding: '16px',
          borderRadius: '0'
        }}
      />
    </>
  )
}

export default Info
