import { useTranslation } from 'react-i18next'
import Button from '../buttons/Button'
import DialogBox from './DialogBox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/sharp-regular-svg-icons'

type Props = {
  dialogActions: {
    [key: string]: () => void
  } | null
  handleClose: () => void
}

const ConfigurationNotPossibleDialog = ({ handleClose }: Props) => {
  const { t } = useTranslation()
  return (
    <DialogBox handleClose={handleClose}>
      <div className="mb-6 flex items-end">
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          className="mr-4 text-[32px] text-red-100"
        />
        <h2 className="text-xl">{t('Konfiguration ej möjlig')}</h2>
      </div>
      <p className="mb-10">
        {t(
          'Konfigurationen är inte möjlig på grund av för höga laster. Försök med andra inställningar eller kontakta oss på '
        )}
        <span className="font-bold">{t('0321-261 60')}</span>.
      </p>
      <div className="flex gap-4">
        <Button onClick={handleClose}>{t('Jag förstår')}</Button>
      </div>
    </DialogBox>
  )
}

export default ConfigurationNotPossibleDialog
