import { StateCreator } from 'zustand'
import { v4 as uuidv4 } from 'uuid'
import { round } from '../lib/utils';

interface Configurator {
  uid: string
  showResults: boolean
  showConditions: boolean
  showPanelSettings: boolean
  showPointerGuides: boolean
  showNewPanelAreaModal: boolean
  isDrawing: boolean
  panelInfo: PanelInfo
  panelInfoLow: PanelInfoLow
  activeArea: string | null
  isRedrawing: boolean
  editPanels: boolean
  mainPosition: Position
  isComplete: boolean
  scale: number
  runImageCreation: boolean
  sectionsOverviewImageView: boolean
  positionsOverviewImageView: boolean
  panelSectionsImageView: boolean
  isConfigurationComplete: boolean
  isApproved: boolean
  isPositionDataValid: boolean
  isRoofMaterialDataValid: boolean
  isRoofPropertiesDataValid: boolean
  roofImageScale: number
  showDialog: string | false
  dialogActions: {
    [key: string]: () => void
  } | null
  disabledDialogActions: {
    [key: string]: boolean
  } | null
  reference?: string
  roofCoordinates: number[][] | undefined
  isLoadingResults: boolean
  isLoaded: boolean
  isEdited: boolean
  climateLoadsError?: string
}

export interface ConfiguratorSlice extends Configurator {
  setShowResults: (showResults: boolean) => void
  setShowConditions: (showConditions: boolean) => void
  toggleShowPointerGuides: () => void
  toggleShowNewPanelAreaModal: () => void
  setShowPanelSettings: (showPanelSettings: boolean) => void
  setPanelInfo: (info: PanelInfo) => void
  setPanelInfoLow: (info: PanelInfoLow) => void
  setIsDrawing: (isDrawing: boolean) => void
  setActiveArea: (activeArea: string | null) => void
  setIsRedrawing: (redrawing: boolean) => void
  setEditPanels: (edit: boolean) => void
  setMainPosition: (position: Position) => void
  setIsComplete: (status: boolean) => void
  setScale: (scale: number) => void
  setRunImageCreation: (runImageCreation: boolean) => void
  setSectionsOverviewImageView: (sectionsOverviewImageView: boolean) => void
  setPositionsOverviewImageView: (positionsOverviewImageView: boolean) => void
  setPanelSectionsImageView: (panelSectionsImageView: boolean) => void
  setIsConfigurationComplete: (isConfigurationComplete: boolean) => void
  setIsApproved: (isApproved: boolean) => void
  setIsPositionDataValid: (isPositionDataValid: boolean) => void
  setIsRoofMaterialDataValid: (isRoofMaterialDataValid: boolean) => void
  setIsRoofPropertiesDataValid: (isRoofPropertiesDataValid: boolean) => void
  setRoofImageScale: (roofImageScale: number) => void
  setShowDialog: (showDialog: string | false) => void
  setDialogActions: (
    dialogActions: {
      [key: string]: () => void
    } | null
  ) => void
  setDisabledDialogActions: (
    dialogActions: {
      [key: string]: boolean
    } | null
  ) => void
  setReference: (reference: string) => void
  setUid: (uid: string) => void
  resetConfigurator: () => void
  setRoofCoordinates: (coordinates: number[][]) => void
  setIsLoadingResults: (isLoadingResults: boolean) => void
  setIsLoaded: (isLoaded: boolean) => void
  setIsEdited: (isEdited: boolean) => void
  setClimateLoadsError: (error?: string) => void
}

const PanelMounting = localStorage.getItem('panelMounting')
const LowSlopePanelMounting = localStorage.getItem('lowSlopePanelMounting')
const panelSystem = localStorage.getItem('panelSystem')
const railDistance = sessionStorage.getItem('selectedRailDistance')

const panelWidth = localStorage.getItem('panelWidth')
  ? Number(localStorage.getItem('panelWidth'))
  : null
const panelHeight = localStorage.getItem('panelHeight')
  ? Number(localStorage.getItem('panelHeight'))
  : null
const panelWeight = localStorage.getItem('panelWeight')
  ? Number(localStorage.getItem('panelWeight'))
  : null
const panelRowGap = localStorage.getItem('panelRowGap')
  ? Number(localStorage.getItem('panelRowGap'))
  : null

const panelInfoValues = {
  width: panelWidth ? panelWidth : 1000,
  height: panelHeight ? panelHeight : 1700,
  weight: panelWeight ? panelWeight : 22,
  widthMounted: panelWidth ? panelWidth : 1000,
  heightMounted: panelHeight ? panelHeight : 1700,
  useSupportPlates:
  localStorage.getItem('useSupportPlates') === 'true' ? true : false,
  useThreeRails: localStorage.getItem('useThreeRails') == 'true' ? true : false,
  gap: 20,
}

const initialState: Configurator = {
  uid: uuidv4(),
  showResults: false,
  showConditions: true,
  showPanelSettings: false,
  showPointerGuides: false,
  showNewPanelAreaModal: false,
  isDrawing: false,
  editPanels: false,
  panelInfo: {
    ...panelInfoValues,
    mounting: PanelMounting ? PanelMounting : '90-portrait',
    selectedRailDistance: railDistance ? parseInt(railDistance) : (PanelMounting?.startsWith("90-") ? round(panelInfoValues.height * 0.6, 0) : round(panelInfoValues.width * 0.6, 0))
  },
  panelInfoLow: {
    ...panelInfoValues,
    mounting: LowSlopePanelMounting ? LowSlopePanelMounting : '2',
    gapRow: panelRowGap ? panelRowGap : 350,
    gapTop: 82,
    system: panelSystem ? panelSystem : 'east/west',
    selectedRailDistance: railDistance ? parseInt(railDistance) : (panelSystem == 'east/west' ? round(panelInfoValues.height * 0.6, 0) : round(panelInfoValues.width * 0.6, 0)),
    useThreeRails: false
  },
  activeArea: null,
  isRedrawing: false,
  mainPosition: { x: 0, y: 0 },
  isComplete: false,
  scale: 1,
  runImageCreation: false,
  sectionsOverviewImageView: false,
  positionsOverviewImageView: false,
  panelSectionsImageView: false,
  isConfigurationComplete: false,
  isApproved: false,
  isPositionDataValid: false,
  isRoofMaterialDataValid: false,
  isRoofPropertiesDataValid: false,
  roofImageScale: 1,
  showDialog: false,
  dialogActions: null,
  disabledDialogActions: null,
  reference: undefined,
  roofCoordinates: undefined,
  isLoadingResults: false,
  isLoaded: false,
  isEdited: false
}

export const createConfiguratorSlice: StateCreator<ConfiguratorSlice> = (
  set
) => ({
  ...initialState,
  setShowResults: (showResults: boolean) => set(() => ({ showResults })),
  setShowConditions: (showConditions: boolean) =>
    set(() => ({ showConditions })),
  setShowPanelSettings: (showPanelSettings: boolean) =>
    set(() => ({ showPanelSettings })),
  toggleShowPointerGuides: () =>
    set((state) => ({ showPointerGuides: !state.showPointerGuides })),
  toggleShowNewPanelAreaModal: () =>
    set((state) => ({ showNewPanelAreaModal: !state.showNewPanelAreaModal })),
  setPanelInfo: (panelInfo) => set(() => ({ panelInfo: panelInfo })),
  setPanelInfoLow: (panelInfo) => set(() => ({ panelInfoLow: panelInfo })),
  setIsDrawing: (isDrawing) => set(() => ({ isDrawing: isDrawing })),
  setActiveArea: (activeArea) => set(() => ({ activeArea: activeArea })),
  setIsRedrawing: (redrawing) => set(() => ({ isRedrawing: redrawing })),
  setEditPanels: (editPanels) => set(() => ({ editPanels })),
  setMainPosition: (position) => set(() => ({ mainPosition: position })),
  setIsComplete: (isComplete) => set(() => ({ isComplete })),
  setScale: (scale) => set(() => ({ scale })),
  setRunImageCreation: (runImageCreation) => set(() => ({ runImageCreation })),
  setSectionsOverviewImageView: (sectionsOverviewImageView) =>
    set(() => ({ sectionsOverviewImageView })),
  setPositionsOverviewImageView: (positionsOverviewImageView) =>
    set(() => ({ positionsOverviewImageView })),
  setPanelSectionsImageView: (panelSectionsImageView) =>
    set(() => ({ panelSectionsImageView })),
  setIsConfigurationComplete: (isConfigurationComplete) =>
    set(() => ({ isConfigurationComplete })),
  setIsApproved: (isApproved) => set(() => ({ isApproved })),
  setIsPositionDataValid: (isPositionDataValid: boolean) =>
    set(() => ({ isPositionDataValid })),
  setIsRoofMaterialDataValid: (isRoofMaterialDataValid: boolean) =>
    set(() => ({ isRoofMaterialDataValid })),
  setIsRoofPropertiesDataValid: (isRoofPropertiesDataValid: boolean) =>
    set(() => ({ isRoofPropertiesDataValid })),
  setRoofImageScale: (roofImageScale: number) =>
    set(() => ({ roofImageScale })),
  setShowDialog: (showDialog: string | false) => set(() => ({ showDialog })),
  setDialogActions: (
    dialogActions: {
      [key: string]: () => void
    } | null
  ) => set(() => ({ dialogActions })),
  setDisabledDialogActions: (
    disabledDialogActions: {
      [key: string]: boolean
    } | null
  ) => set(() => ({ disabledDialogActions })),
  setReference: (reference: string) => set(() => ({ reference })),
  setUid: (uid: string) => set(() => ({ uid })),
  resetConfigurator: () => {
    set(() => {
      return {
        ...initialState,
        uid: uuidv4()
      }
    })
  },
  setRoofCoordinates: (roofCoordinates: number[][]) =>
    set(() => ({ roofCoordinates })),
  setIsLoadingResults: (isLoadingResults: boolean) =>
    set(() => ({ isLoadingResults })),
  setIsLoaded: (isLoaded: boolean) => set(() => ({ isLoaded })),
  setIsEdited: (isEdited: boolean) => set(() => ({ isEdited })),
  setClimateLoadsError: (climateLoadsError?: string) =>
    set(() => ({ climateLoadsError }))
})
